import { baseAxios } from "../index";

export async function tambahCabang(data) {
  try {
    const response = await baseAxios.post("webCabang/tambahCabang", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllCabang(dispatch, data) {
  try {
    const response = await baseAxios.get(
      `webCabang/getAllCabang?idWilayah=${data.idWilayah}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALLCABANG", data: response.data });
    // console.log(response, "CABANg");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getAllAnggotaCabang(dispatch, data) {
  try {
    const response = await baseAxios.get(
      `webCabang/getAllAnggotaCabang?idCabang=${data.idCabang}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALLANGGOTACABANG", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function tambahTargetCabang(data) {
  try {
    const response = await baseAxios.post(
      "webCabang/tambahTargetCabang",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllTargetCabang(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `webCabang/getAllTargetCabang?idClient=${data.idClient}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TARGET_CABANG", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function editTargetCabang(data) {
  try {
    const response = await baseAxios.patch("webCabang/ubahTargetCabang", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
