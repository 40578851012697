import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from "moment";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import GroupCalonNasabah from "./groupCalonNasabah";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Dna } from "react-loader-spinner";
import { getAllCalonNasabah } from "stores";

function CalonNasabah() {
  const dispatch = useDispatch();
  const korcab = useSelector((state) => state.korcabReducer);
  //   console.log(korcab, "KORCAB");
  const [listDataCalonNasabah, setListDataCalonNasabah] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [idCalonNasabah, setIdCalonNasabah] = React.useState("");
  const [sendVal, setSendVal] = React.useState("");

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handleFilterChange = () => {
    setIsLoading(true);
    setPage(0);
    getAllCalonNasabah(dispatch, {
      page: page + 1,
      skip: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleDetailsClick = (val) => {
    dispatch({ type: "SET_DETAIL_NASABAH", data: false });
    setIdCalonNasabah(val._id);
    setSendVal(val);
    // dispatch({ type: "SET_KIRIM_DETAIL_NASABAH", data: val });
  };

  React.useEffect(() => {
    let tmp =
      korcab.listCalonNasabah.docs &&
      korcab.listCalonNasabah.docs.map((val) => {
        // console.log(val, "LLLLL");
        return {
          ...val,
          // marketingName: val.marketing?.nama || "",
          // marketingNopeg: val.marketing?.nopeg || "",
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Details Nasabah</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#F75D59",
                    border: 0,
                  }}
                  onClick={() => {
                    // console.log(val);
                    handleDetailsClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>
            </>
          ),
        };
      });
    setListDataCalonNasabah(tmp);
  }, [korcab.listCalonNasabah]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const getTypeKunjungan = (status) => {
    switch (status) {
      case "Dinas":
        return {
          fontWeight: "bold",
          backgroundColor: "#DDF3F4",
          textColor: "#0C8990",
        };
      case "Personal":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFF6E6",
          textColor: "#FFA700",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colKunjungan = [
    {
      name: "No",
      width: "100px",
      center: true,
      // selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
      selector: (row, index) => index + korcab.listCalonNasabah.pagingCounter,
    },
    {
      name: "Nama Nasabah",
      minWidth: "290px",
      center: true,
      selector: (row) => row.namaNasabah,
    },
    {
      name: "NIK",
      minWidth: "190px",
      center: true,
      selector: (row) => (!row.nik ? "-" : row.nik),
    },
    {
      name: "No Hp",
      minWidth: "190px",
      center: true,
      selector: (row) => row.noHp,
    },
    {
      name: "Tgl Lahir",
      minWidth: "190px",
      center: true,
      selector: (row) => moment(row.tglLahir).format("DD MMMM YYYY"),
    },
    {
      name: "Marketing On Going",
      minWidth: "290px",
      center: true,
      selector: (row) => (!row.marketing ? "-" : row.marketing.nama),
    },
    {
      name: "Nopeg Marketing",
      minWidth: "250px",
      center: true,
      selector: (row) => (!row.marketing ? "-" : row.marketing.nopeg),
    },
    {
      name: "Kategori Nasabah",
      minWidth: "250px",
      center: true,
      selector: (row) =>
        row.kategoriNasabah === "1"
          ? "PNS Biasa"
          : row.kategoriNasabah === "2"
          ? "PNS Prapurna Bakti"
          : row.kategoriNasabah === "3"
          ? "Pensiunan"
          : row.kategoriNasabah === "4"
          ? "PRA THT"
          : "KPS (Kredit Pensiunan)",
    },
    {
      name: "Tipe Kunjungan",
      minWidth: "250px",
      center: true,
      selector: (row) => row.typeKunjungan,
      cell: (row) => {
        const statusStyle = getTypeKunjungan(row.typeKunjungan);
        const tampilanLabel = row.typeKunjungan;

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setIsLoading(true);
    getAllCalonNasabah(dispatch, {
      page: page + 1,
      limit: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        // setLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman]);
  return (
    <>
      {korcab.modalDetailNasabah ? (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title
                    as="h3"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    calon nasabah
                  </Card.Title>
                  <Tab.Content>
                    <Card style={{ border: 0, marginTop: 20 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        <Col md={"4"}>
                          <InputGroup>
                            <Form.Control
                              style={{
                                paddingLeft: "10px",
                              }}
                              type="text"
                              value={searchText}
                              onChange={handleSearch}
                              placeholder="Reffrence ID/Nasabah"
                              onKeyDown={handleKeyDown}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text
                                style={{ cursor: "pointer" }}
                                onClick={handleClearSearch}
                              >
                                <i class="fas fa-times"></i>
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                        <Col>
                          <Button
                            // variant="info"
                            style={{
                              fontSize: 16,
                              backgroundColor: "#F75D59",
                              border: 0,
                              paddingTop: 10,
                              paddingBottom: 7,
                            }}
                            onClick={handleFilterChange}
                          >
                            <i class="fas fa-search"></i>
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Tab.Content>
                </Card.Header>
                <Card.Body>
                  {isLoading ? (
                    <div
                      className="loader-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "30vh",
                      }}
                    >
                      <Dna
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                      />
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <div>
                      <DataTable
                        columns={colKunjungan}
                        data={listDataCalonNasabah}
                        customStyles={customStyles}
                        paginationPerPage={totalPerHalaman}
                        progressPending={isLoading}
                      />
                      <div style={{ marginTop: 35 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col md={4}>
                            <Row style={{ alignItems: "center" }}>
                              <div
                                style={{
                                  paddingRight: 0,
                                  marginLeft: 20,
                                  marginRight: 10,
                                }}
                              >
                                <label style={{ fontWeight: "bold" }}>
                                  Rows per page
                                </label>
                              </div>
                              <Col md="4" style={{ paddingLeft: 0 }}>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="Pilih Client"
                                  value={options.find(
                                    (option) => option.value === totalPerHalaman
                                  )}
                                  onChange={handlePageSizeChange}
                                  options={options}
                                  placeholder="Rows per page"
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <ReactPaginate
                              forcePage={page}
                              containerClassName="paginations justify-content-end"
                              previousClassName="pages-itemss-containers"
                              previousLinkClassName="pages-links-labelss"
                              nextClassName="pages-itemss-containers"
                              nextLinkClassName="pages-links-labelss"
                              breakClassName="pages-itemss-containers"
                              pageCount={korcab.listCalonNasabah.totalPages}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={1}
                              pageLinkClassName="pages-links-labelss"
                              pageClassName="pages-itemss-containers"
                              activeClassName="activees"
                              activeLinkClassName="aactivess"
                              nextLabel=" > "
                              previousLabel=" < "
                              initialPage={page}
                              disableInitialCallback={true}
                              onPageChange={(val) => {
                                setPage(val.selected);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <GroupCalonNasabah data={idCalonNasabah} value={sendVal} />
        </>
      )}
    </>
  );
}

export default CalonNasabah;
