import React from "react";
import { Button, Card, Form, Col, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";

function Step4() {
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const valDetail = pengajuan.detailPengajuan;
  const jsonJenisKredit = pengajuan.jsonPengajuan.jenisKreditKonsumtif;
  // console.log(pengajuan, "AAAAAAAAAAAAAA");
  // console.log(jsonJenisKredit, "STEPPP4");
  const jenisKredit = jsonJenisKredit.find((value) => {
    return pengajuan.detailPengajuan.stepEmpat.jenisKredit === value.value;
  });
  // console.log(jenisKredit, "JENIS KREDITTTTTTTTTTTT");
  // console.log(valDetail, "LLLLLLLLLL");
  const jsonTujuanPenggunaan =
    pengajuan.jsonPengajuan.tujuanPenggunaanIndividual;
  const tujuanPenggunaan = jsonTujuanPenggunaan.find((value) => {
    return pengajuan.detailPengajuan.stepEmpat.tujuanPenggunaan === value.value;
  });
  const formatRupiah = (angka) => {
    const numberFormat = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return numberFormat.format(angka);
  };

  return (
    <Col md="12" style={{ marginTop: 20 }}>
      <Card.Header>
        <Card.Title
          as="h4"
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          infomrasi pinjaman
          <hr></hr>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card style={{ border: 0 }}>
          <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
            <div className="row">
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  tujuan penggunaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {tujuanPenggunaan.label}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  tenor
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.tenor} Bulan
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nominal permohonan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {formatRupiah(valDetail.stepEmpat.nominalPermohonan)}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nominal pencairan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {formatRupiah(valDetail.nominalPencairan)}
                  </Label>
                </li>
              </div>
              {valDetail.stepEmpat.statusPinjaman !== "1" ? (
                <>
                  <div className="col-6">
                    <Label
                      style={{
                        fontSize: 16,
                        marginTop: 15,
                        fontWeight: "bold",
                        color: "#828286",
                        textTransform: "uppercase",
                      }}
                    >
                      nominal Pelunasan
                    </Label>
                    <li>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 15,
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {isNaN(valDetail.nominalPelunasan)
                          ? "Rp 0"
                          : formatRupiah(valDetail.nominalPelunasan)}
                      </Label>
                    </li>
                  </div>
                </>
              ) : null}

              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nip
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.nip}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  suku bunga
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.sukuBunga}%
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  Jenis Kredit
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {jenisKredit.label}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  detail tujuan penggunaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.detailTujuanPenggunaan}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  status pinjaman
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.statusPinjaman === "1"
                      ? "New"
                      : valDetail.stepEmpat.statusPinjaman === "2"
                      ? "Take Over"
                      : valDetail.stepEmpat.statusPinjaman === "3"
                      ? "Top Up"
                      : "Top Up"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  cabang pencairan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.idCabangPencairan ===
                    valDetail.cabang.namaCabang
                      ? valDetail.cabang.namaCabang
                      : valDetail.cabang.namaCabang}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  pembayaran gaji melalui
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.pembayaranGajiMelalui === "1"
                      ? "PT BPD Sulutgo"
                      : valDetail.stepEmpat.pembayaranGajiMelalui === "2"
                      ? "Instansi Dinas"
                      : valDetail.stepEmpat.pembayaranGajiMelalui === "3"
                      ? "Perusahaan"
                      : "Bank Lain"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  alamat cabang
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.cabang.alamat}
                  </Label>
                </li>
              </div>
            </div>
          </div>
        </Card>
      </Card.Body>
    </Col>
  );
}
export default Step4;
