const initialState = {
  modalDetailNasabah: true,
  modalKunjunganByGroup: true,
  listKorcab: [],
  listCalonNasabah: [],
  detailNasabah: [],
  groupKujunganById: [],
  listDataKunjunganByGroup: [],
};

const korcabReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_DETAIL_NASABAH":
      return {
        ...state,
        modalDetailNasabah: data,
      };
    case "SET_KUNJUNGAN_BY_GROUP":
      return {
        ...state,
        modalKunjunganByGroup: data,
      };
    case "SET_ALL_KORCAB":
      return {
        ...state,
        listKorcab: data,
      };
    case "SET_ALL_CALON_NASABAH":
      return {
        ...state,
        listCalonNasabah: data,
      };
    case "SET_KIRIM_DETAIL_NASABAH":
      return {
        ...state,
        detailNasabah: data,
      };
    case "SET_GROUP_KUNJUNGAN_BYID":
      return {
        ...state,
        groupKujunganById: data,
      };
    case "SET_LIST_KUNJUNGAN_BYGROUP":
      return {
        ...state,
        listDataKunjunganByGroup: data,
      };
    default:
      return state;
  }
};
export default korcabReducer;
