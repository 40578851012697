import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { Dna, Watch } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getMarketing } from "stores";
import ListMarketingByGetMarketing from "./ListMarketingByGetMarketing";

function CabangKunjunganMarketing() {
  const dispatch = useDispatch();
  const kunjungan = useSelector((state) => state.kunjunganReducer);
  const auth = useSelector((state) => state.authReducer);
  const [isLoading, setIsLoading] = React.useState(true);
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const [idCabang, setIdCabang] = React.useState("");
  const [idMarketing, setIdMarketing] = React.useState("");
  const [listDataMarketing, setListDataMarketing] = React.useState([]);
  const [sendDataMarketing, setSendDataMarketing] = React.useState([]);
  const [sendDataCabang, setSendDataCabang] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDetailsClick = (val) => {
    dispatch({ type: "SET_DETAIL_MARKETING", data: false });
    setSendDataMarketing(val.marketing);
    setSendDataCabang(val.cabang);
  };

  React.useEffect(() => {
    let tmp =
      kunjungan.dataGetMarketing &&
      kunjungan.dataGetMarketing.map((val) => {
        return {
          ...val,
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#F75D59",
                    border: 0,
                  }}
                  onClick={() => {
                    handleDetailsClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>{" "}
            </>
          ),
        };
      });
    setListDataMarketing(tmp);
  }, [kunjungan.dataGetMarketing]);

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colKunjungan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Kode Cabang",
      minWidth: "250px",
      center: true,
      selector: (row) => row.kodeCabang,
    },
    {
      name: "Nama Cabang",
      minWidth: "250px",
      center: true,
      selector: (row) => row.namaCabang,
    },
    {
      name: "Total Kunjungan",
      minWidth: "250px",
      center: true,
      selector: (row) => `${row.totalKunjungan} Kunjungan`,
    },
    {
      name: "Total Marketing",
      minWidth: "250px",
      center: true,
      selector: (row) => `${row.marketing.length} Marketing`,
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setIsLoading(true);
    getMarketing(dispatch, {
      tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
      tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
      idCabang: idCabang,
      idMarketing: idMarketing,
    })
      .then((response) => {
        // setLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [tglAwal, tglAkhir]);

  return (
    <>
      {kunjungan.detailGetMarketing ? (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title
                    // as="h3"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      fontSize: 25,
                    }}
                  >
                    list kunjungan cabang
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
                <Tab.Content>
                  <Card style={{ border: 0, marginTop: 20 }}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <Col md={2} style={{ marginTop: 20, marginLeft: 25 }}>
                        <h5
                          className="title mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Tanggal Awal
                        </h5>
                        <div>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0);
                              setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAwal}
                            initialValue={tglAwal}
                            timeFormat={false}
                          />
                        </div>
                      </Col>
                      <Col md={2} style={{ marginTop: 20 }}>
                        <h5
                          className="title mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Tanggal Akhir
                        </h5>
                        <div>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0);
                              setTglAkhir(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAkhir}
                            initialValue={tglAkhir}
                            timeFormat={false}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Tab.Content>
                <Card.Body>
                  {isLoading ? (
                    <div
                      className="loader-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "30vh",
                      }}
                    >
                      <Watch
                        visible={true}
                        height="80"
                        width="80"
                        radius="30"
                        color="#F75D59"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <div>
                      <DataTable
                        columns={colKunjungan}
                        data={listDataMarketing}
                        customStyles={customStyles}
                        pagination
                        paginationPerPage={itemsPerPage}
                        onChangePage={handlePageChange}
                        //   paginationPerPage={totalPerHalaman}
                        // progressPending={isLoading}
                      />
                      {/* <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label style={{ fontWeight: "bold" }}>
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChange}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={page}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-itemss-containers"
                            previousLinkClassName="pages-links-labelss"
                            nextClassName="pages-itemss-containers"
                            nextLinkClassName="pages-links-labelss"
                            breakClassName="pages-itemss-containers"
                            pageCount={kunjungan.dataKunjungan.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labelss"
                            pageClassName="pages-itemss-containers"
                            activeClassName="activees"
                            activeLinkClassName="aactivess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={page}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPage(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div> */}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <ListMarketingByGetMarketing
            data={sendDataMarketing}
            cabang={sendDataCabang}
          />
        </>
      )}
    </>
  );
}

export default CabangKunjunganMarketing;
