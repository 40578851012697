import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import Moment from "moment";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  Modal,
  ModalBody,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import { number } from "prop-types";
import { getAllPencairan } from "stores/Pengajuan/function";
import { Dna } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import { getAllWilayah } from "stores";

function Pencairan() {
  const dataPencairan = useSelector((state) => state.pengajuanReducer);
  const wilayah = useSelector((state) => state.dashboardUtamaReducer);
  const [modalDetails, setModalDetails] = React.useState(false);
  const [detailPencairan, setDetailPencairan] = React.useState([]);
  // console.log(dataPencairan.listPencairan.data, "Dari Store");
  const dispatch = useDispatch();
  const [listPencairan, setListPencairan] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  Moment.locale("id");
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const tanggalAkad = Moment(detailPencairan.tglAkad).format("DD MMMM YYYY");
  const tanggalAgenda = Moment(detailPencairan.tglAgenda).format(
    "DD MMMM YYYY"
  );

  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [listWilayah, setListWilayah] = React.useState([]);
  const [idWilayah, setIdWilayah] = React.useState({
    label: "All",
    value: "",
  });

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };
  const formatRupiah = (angka) => {
    const numberFormat = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return numberFormat.format(angka);
  };

  const handleFilterChange = () => {
    setIsLoading(true);
    setPage(0);
    getAllPencairan(dispatch, {
      status: "Disetujui",
      page: 1,
      limit: totalPerHalaman,
      cari: searchText,
      tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
      tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
      idWilayah: idWilayah.value,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  React.useEffect(() => {
    // console.log(dataPencairan.listPencairan, ">>>>>>>>>>>>>>>>>");
    if (dataPencairan.listPencairan.data) {
      let tmp = dataPencairan.listPencairan.data.map((val) => {
        return {
          ...val,
          namaCabangPencairan: !val.lokasiPencairan
            ? "-"
            : val.lokasiPencairan.namaCabang,
          nominalPermohonan: !val.nominalPermohonan
            ? "-"
            : val.stepEmpat.nominalPermohonan,
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Details Pencairan</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#30B9FB",
                    border: 0,
                  }}
                  onClick={() => {
                    // console.log(val, "KKK");
                    setModalDetails(true);
                    setDetailPencairan(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>
            </>
          ),
        };
      });
      setListPencairan(tmp);
    }
  }, [dataPencairan.listPencairan]);

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colPencairan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) =>
        index + dataPencairan.listPencairan.pagingCounter,
    },
    {
      name: "Refference ID",
      minWidth: "250px",
      center: true,
      selector: (row) => row.id_pinjaman,
    },
    {
      name: "Nasabah",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaLengkap,
    },
    {
      name: "Wilayah",
      minWidth: "200px",
      center: true,
      selector: (row) => row.wilayah.namaWilayah,
    },
    {
      name: "Nominal Permohonan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.stepEmpat.nominalPermohonan);
        return formattedValue;
      },
    },
    {
      name: "Nominal Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalPencairan);
        return formattedValue;
      },
    },
    {
      name: "Cabang Realisasi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaCabangPencairan,
    },
    {
      name: "No Akad",
      minWidth: "200px",
      center: true,
      selector: (row) => row.noAkad,
    },
    {
      name: "Tanggal Akad",
      minWidth: "250px",
      center: true,
      sortable: true,
      selector: (row) => moment(new Date(row.tglAkad)).format("DD MMMM YYYY"),
    },
    {
      name: "No Agenda",
      minWidth: "200px",
      center: true,
      selector: (row) => row.noAgenda,
    },
    {
      name: "Tanggal Agenda",
      minWidth: "200px",
      center: true,
      selector: (row) => moment(new Date(row.tglAgenda)).format("DD MMMM YYYY"),
    },
    {
      name: "KTP",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nomorIdentitas,
    },
    {
      name: "Detail",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    let tmp = [{ value: "", label: "All" }];
    wilayah.allWilayah &&
      wilayah.allWilayah.map((val) => {
        // console.log(val, "VAL WILAYAH");
        tmp.push({
          value: val._id,
          label: val.namaWilayah,
        });
      });
    setListWilayah(tmp);
    let Obj = wilayah.allWilayah.find((x) => x.namaWilayah === "Gorontalo");
    // setIdWilayah({ value: Obj._id, label: Obj.namaWilayah });
    // console.log(Obj, ">>>>>>>>>>>>>>>>");
  }, [wilayah.allWilayah]);

  React.useEffect(() => {
    getAllWilayah(dispatch).then((response) => {
      // const dataCabang = response.data;
      // setTotalCbg(dataCabang);
    });
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    getAllPencairan(dispatch, {
      tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
      tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
      status: "Disetujui",
      page: page + 1,
      limit: totalPerHalaman,
      cari: searchText,
      idWilayah: idWilayah.value,
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
    // getAllPencairan(dispatch, { status: "Disetujui", tglAwal: "tgl" });
  }, [page, totalPerHalaman, idWilayah]);

  return (
    <>
      <Modal
        size="lg"
        show={modalDetails}
        onHide={() => {
          // handleModalClose();
          setModalDetails(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12">
          <Card.Title
            as="h4"
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontFamily: "sans-serif",
              marginLeft: 13,
            }}
          >
            details
            <hr></hr>
          </Card.Title>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Body>
              <Form action="#" method="#">
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Refference ID
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.id_pinjaman}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nasabah
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.namaLengkap}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nominal Permohonan
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {formatRupiah(
                        !detailPencairan.stepEmpat
                          ? ""
                          : detailPencairan.stepEmpat.nominalPermohonan
                      )}
                      {/* {!detailPencairan.stepEmpat
                        ? ""
                        : `Rp ${detailPencairan.stepEmpat.nominalPermohonan.toLocaleString(
                            "id-ID"
                          )}`} */}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nominal Pencairan
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {formatRupiah(detailPencairan.nominalPencairan)}

                      {/* {!detailPencairan.nominalPencairan
                        ? ""
                        : `Rp ${detailPencairan.nominalPencairan.toLocaleString(
                            "id-ID"
                          )}`} */}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nominal Pelunasan
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {isNaN(detailPencairan.nominalPelunasan)
                        ? "Rp 0"
                        : formatRupiah(detailPencairan.nominalPelunasan)}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      No Akad
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.noAkad}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Tanggal Akad
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {tanggalAkad}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      No Agenda
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.noAgenda}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Tanggal Agenda
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {tanggalAgenda}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Jenis Asuransi
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.jenisAsuransi}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Biaya Asuransi
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {!detailPencairan.biayaAsuransi
                        ? ""
                        : `Rp ${detailPencairan.biayaAsuransi.toLocaleString(
                            "id-ID"
                          )}`}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Biaya Provisi
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {!detailPencairan.provisi
                        ? ""
                        : `Rp ${detailPencairan.provisi.toLocaleString(
                            "id-ID"
                          )}`}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Blokir Angsuran
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {!detailPencairan.blokirAngsuran
                        ? ""
                        : `Rp ${detailPencairan.blokirAngsuran.toLocaleString(
                            "id-ID"
                          )}`}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Analis
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.analis}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      KTP
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailPencairan.nomorIdentitas}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Cabang Realisasi
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {!detailPencairan.lokasiPencairan
                        ? ""
                        : detailPencairan.lokasiPencairan.namaCabang}
                    </label>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Pencairan
                </Card.Title>
                {/* <p className="card-category">Plain text tabs</p> */}
                <Tab.Content>
                  <Card
                    style={{
                      border: 0,
                      // backgroundColor: "aquamarine",
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Col md={2} style={{ marginTop: 20, marginLeft: 25 }}>
                        <h5
                          className="title mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Tanggal Awal
                        </h5>
                        <div>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0);
                              setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAwal}
                            initialValue={tglAwal}
                            timeFormat={false}
                          />
                        </div>
                      </Col>
                      <Col md={2} style={{ marginTop: 20 }}>
                        <h5
                          className="title mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Tanggal Akhir
                        </h5>
                        <div>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0);
                              setTglAkhir(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAkhir}
                            initialValue={tglAkhir}
                            timeFormat={false}
                          />
                        </div>
                      </Col>
                      <Col md={4} style={{ marginTop: 65 }}>
                        <InputGroup>
                          <Form.Control
                            style={{ paddingLeft: "10px" }}
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Reffrence ID/Nasabah"
                            onKeyDown={handleKeyDown}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearch}
                            >
                              <i className="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                      <Col md={2} style={{ marginTop: 70 }}>
                        <Button
                          style={{
                            fontSize: 16,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingTop: 10,
                            paddingBottom: 8,
                            marginLeft: 10,
                          }}
                          onClick={handleFilterChange}
                        >
                          <i className="fas fa-search"></i>
                        </Button>
                      </Col>
                    </Row>
                    <Col md={"4"} style={{ marginTop: 20 }}>
                      <label style={{ color: "black", fontSize: 14 }}>
                        Pilih Wilayah
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        defaultValue={{
                          label: "All",
                          value: "",
                        }}
                        onChange={(value) => {
                          setIdWilayah(value);
                        }}
                        options={listWilayah}
                      />
                    </Col>
                  </Card>
                </Tab.Content>
              </Card.Header>
              <Card.Body>
                {isLoading ? (
                  <div
                    className="loader-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "30vh",
                    }}
                  >
                    <Dna
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <div>
                    <DataTable
                      columns={colPencairan}
                      data={listPencairan}
                      // {listPencairan.filter(
                      //   (row) =>
                      //     row.id_pinjaman
                      //       .toLowerCase()
                      //       .includes(searchText.toLowerCase()) ||
                      //     row.namaLengkap
                      //       .toLowerCase()
                      //       .includes(searchText.toLowerCase())
                      // )}
                      customStyles={customStyles}
                      paginationPerPage={totalPerHalaman}
                      progressPending={isLoading}
                    />
                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label style={{ fontWeight: "bold" }}>
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChange}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={page}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-items-containers"
                            previousLinkClassName="pages-links-labels"
                            nextClassName="pages-items-containers"
                            nextLinkClassName="pages-links-labels"
                            breakClassName="pages-items-containers"
                            pageCount={dataPencairan.listPencairan.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labels"
                            pageClassName="pages-items-containers"
                            activeClassName="actives"
                            activeLinkClassName="activess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={page}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPage(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Pencairan;
