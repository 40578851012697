import React from "react";
import DataTable from "react-data-table-component";
// import Select from "react-select";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import InputTargetAreaManager from "./SetTargetAreaManager";
import { getAllTargetAreaManager } from "stores";
import moment from "moment";
import Select from "react-select";
import Swal from "sweetalert2";
import { getAllClient } from "stores";
import { editTargetAreaManager } from "stores";
import CurrencyInput from "react-currency-input-field";
import { Dna } from "react-loader-spinner";

function TargetAreaManager() {
  const dispatch = useDispatch();
  const statusInputTargetAM = useSelector((state) => state.areaManagerReducer);
  const targetAreaManager = useSelector((state) => state.areaManagerReducer);
  const auth = useSelector((state) => state.authReducer);
  const [listTargetAreaManager, setListAreamanager] = React.useState([]);
  const storeClient = useSelector((state) => state.clientReducer);
  const [filterClient, setFilterClient] = React.useState(null);
  const [listClient, setListClient] = React.useState([]);
  const [nominal, setNominal] = React.useState("");
  const [modalEditTarget, setModalEditTarget] = React.useState(false);
  const [idTarget, setIdTarget] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  const submitEditTarget = () => {
    // console.log(nominal, idTarget);
    if (nominal === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Nominal tidak boleh kosong",
      });
    } else if (nominal === "0") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Nominal tidak boleh kosong",
      });
    } else if (nominal === undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Nominal tidak boleh kosong",
      });
    } else {
      editTargetAreaManager({
        idTarget: idTarget,
        nominal: nominal,
      }).then((response) => {
        // console.log(response, ">>>>>>>.");
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.message,
          });
          setModalEditTarget(false);
          getAllTargetAreaManager(dispatch, { idClient: "" });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.message,
          });
        }
      });
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleFilterChange = (selectedOption) => {
    setFilterClient(selectedOption);
    getAllTargetAreaManager(dispatch, { idClient: selectedOption.value });
  };
  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeClient.listClient &&
      storeClient.listClient.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaClient,
        });
      });

    setListClient(tmp);
  }, [storeClient.listClient]);

  React.useEffect(() => {
    let tmp =
      targetAreaManager.listTargetAreaManager &&
      targetAreaManager.listTargetAreaManager.map((val) => {
        return {
          ...val,
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Edit Target</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="success"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#23E69D",
                    border: 0,
                  }}
                  onClick={() => {
                    setModalEditTarget(true);
                    setIdTarget(val._id);
                  }}
                >
                  <i class="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>
            </>
          ),
        };
      });
    setListAreamanager(tmp);
  }, [targetAreaManager.listTargetAreaManager]);

  const handleClick = () => {
    dispatch({ type: "SET_STATUS_INPUT_TARGET_AREAMANAGER", data: false });
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colTargetAreaManager = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => index + 1,
    },
    {
      name: "No Karyawan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Nama",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "BPD",
      minWidth: "150px",
      center: true,
      selector: (row) => row.namaClient,
    },
    {
      name: "Bulan",
      minWidth: "100px",
      center: true,
      selector: (row) => moment(new Date(row.tanggal)).format("MMMM"),
    },
    {
      name: "Tahun",
      minWidth: "100px",
      center: true,
      selector: (row) => moment(new Date(row.tanggal)).format("YYYY"),
    },
    {
      name: "Target",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.nominal.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Aksi",
      minWidth: "100px",
      omit: auth.role === "super admin" || auth.role === "admin" ? false : true,
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    getAllTargetAreaManager(dispatch, { idClient: "" });
    dispatch({ type: "SET_STATUS_INPUT_TARGET_AREAMANAGER", data: true });
    getAllClient(dispatch);
  }, []);

  React.useEffect(() => {
    getAllTargetAreaManager(dispatch, { idClient: "" })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [statusInputTargetAM.statusInputTargetAM]);
  return (
    <>
      <Modal
        size="lg"
        show={modalEditTarget}
        onHide={() => {
          // handleModalClose();
          setModalEditTarget(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h5"
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                edit target area manager
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Row>
                  <Col md="12">
                    <CurrencyInput
                      style={{ padding: 5, borderRadius: 5 }}
                      name="nominal"
                      placeholder="Masukkan nominal"
                      prefix="Rp "
                      onValueChange={(value) => {
                        setNominal(value);
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitEditTarget}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {statusInputTargetAM.statusInputTargetAM ? (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title
                    as="h3"
                    style={{
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    target area manager
                  </Card.Title>
                </Card.Header>
                <Tab.Container>
                  <Col>
                    {auth.role === "super admin" || auth.role === "admin" ? (
                      <Button
                        className="btn-wd mr-1"
                        variant="primary"
                        style={{
                          marginLeft: 10,
                          marginTop: 20,
                          backgroundColor: "#00BFFF",
                          border: 0,
                        }}
                        onClick={handleClick}
                      >
                        Set Target
                      </Button>
                    ) : null}
                  </Col>
                  <Tab.Content>
                    <Card style={{ border: 0 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col
                          md={"4"}
                          style={{
                            marginTop: 20,
                            marginRight: 25,
                            order: auth.role === "super admin" ? 2 : 1,
                          }}
                        >
                          {auth.role === "super admin" ? (
                            <div>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={filterClient}
                                onChange={(selectedOption) =>
                                  handleFilterChange(selectedOption)
                                }
                                options={[
                                  { value: "", label: "All" },
                                  ...listClient,
                                ]}
                                placeholder="Pilih Client"
                              />
                            </div>
                          ) : null}
                        </Col>

                        <Col
                          md={"4"}
                          style={{
                            marginTop: 20,
                            marginRight: 25,
                            order: 1,
                            marginLeft: 25,
                          }}
                        >
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <i className="nc-icon nc-zoom-split icon-bold"></i>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              style={{
                                paddingLeft: "10px",
                              }}
                              type="text"
                              value={searchText}
                              onChange={handleSearch}
                              placeholder="No Karyawan/Nama AM"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Card>
                  </Tab.Content>
                </Tab.Container>
                <Card.Body>
                  {isLoading ? (
                    <div
                      className="loader-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "30vh",
                      }}
                    >
                      <Dna
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                      />
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <DataTable
                      columns={colTargetAreaManager}
                      data={listTargetAreaManager.filter(
                        (row) =>
                          row.nopeg
                            .toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          row.nama
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                      )}
                      customStyles={customStyles}
                      pagination
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <InputTargetAreaManager />
      )}
    </>
  );
}

export default TargetAreaManager;
