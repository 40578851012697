import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import { Dna, Watch } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { getAllKunjunganByLogin } from "stores/kunjungan/function";
import { updateApprovalKunjungan } from "stores";
import GoogleMapReact from "google-map-react";
import { exportPdfKunjungan } from "stores";

function ListKunjungan() {
  const dispatch = useDispatch();
  const kunjungan = useSelector((state) => state.kunjunganReducer);
  const auth = useSelector((state) => state.authReducer);
  const [listDataKunjungan, setListDataKunjungan] = React.useState([]);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [buktiKunjungan, setBuktiKunjungan] = React.useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [pilihOpsi, setPilihOpsi] = React.useState("");
  const [listStatus, setListStatus] = React.useState([]);

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handleFilterChange = () => {
    setIsLoading(true);
    getAllKunjunganByLogin(dispatch, {
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        setIsLoading(false); // Setelah selesai memuat data
      })
      .catch((error) => {
        setIsLoading(false); // Jika terjadi error
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  React.useEffect(() => {
    let tmp =
      kunjungan.dataKunjungan.docs &&
      kunjungan.dataKunjungan.docs.map((val) => {
        const isDisabled =
          (auth.role === "client" &&
            (!val.approvalSupervisor || val.approvalKacap === true)) ||
          (auth.role === "supervisor" && val.approvalSupervisor) ||
          val.keputusan === "Ditolak";
        return {
          ...val,
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#F75D59",
                    border: 0,
                  }}
                  onClick={() => {
                    // console.log(val);
                    setModalDetail(!modalDetail);
                    setBuktiKunjungan(val.buktiKunjungan);
                    setLatitude(parseFloat(val.lat));
                    setLongitude(parseFloat(val.long));
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>
              {(auth.role === "client" && auth.jabatan === "korcab") ||
              auth.role === "super admin" ||
              auth.role === "supervisor" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Approval Kunjungan Nasabah</Tooltip>}
                >
                  <Button
                    className="ml-2"
                    disabled={isDisabled}
                    style={{
                      marginTop: 5,
                      backgroundColor: isDisabled ? "grey" : "#2CC4A0",
                      border: 0,
                      cursor: isDisabled ? "not-allowed" : "pointer",
                    }}
                    onClick={async () => {
                      const loadingSwal = Swal.fire({
                        title: "Loading...",
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        onBeforeOpen: () => {
                          Swal.showLoading();
                        },
                      });

                      try {
                        const result = await Swal.fire({
                          title: "Approve Kunjungan",
                          text: `Apakah anda yakin menyetujui ${val.calonNasabah.namaNasabah} tersebut ?`,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Ya",
                        });

                        if (result.isConfirmed) {
                          const response = await updateApprovalKunjungan({
                            idKunjungan: val._id,
                          });
                          loadingSwal.close();

                          Swal.fire({
                            title: "Sukses",
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 2000,
                          });

                          getAllKunjunganByLogin(dispatch, {
                            page: page + 1,
                            limit: totalPerHalaman,
                          });
                        }
                      } catch (error) {
                        loadingSwal.close();
                        if (error.response && error.response.status === 400) {
                          Swal.fire({
                            title: "Error",
                            text: error.response.data.message,
                            icon: "error",
                            showConfirmButton: true,
                          });
                        }
                      }
                    }}
                  >
                    <i className="fas fa-check"></i>
                  </Button>
                </OverlayTrigger>
              ) : null}
            </>
          ),
        };
      });
    setListDataKunjungan(tmp);
  }, [kunjungan.dataKunjungan, auth]);

  const AnyReactComponent = ({ text }) => (
    <div style={{ color: "red", fontWeight: "bold" }}>
      <i className="fas fa-map-marker-alt"></i> {text}
    </div>
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  React.useEffect(() => {
    let tmp = [
      {
        value: "Batal",
        label: "Batal",
      },

      {
        value: "Tunda",
        label: "Tunda",
      },
    ];
    setListStatus(tmp);
  }, [kunjungan.dataKunjungan, page, totalPerHalaman]);

  const defaultProps = {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 11,
  };

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const getHasil = (hasilNasabah) => {
    switch (hasilNasabah) {
      case "Batal":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "Pinjam":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3EEF9",
          textColor: "#1569C7",
        };
      case "-":
        return {
          fontWeight: "bold",

          textColor: "#000000",
        };
      default:
        return {};
    }
  };

  const getKeputusan = (keputusan) => {
    switch (keputusan) {
      case "Tunda":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE6CC",
          textColor: "#FF7F00",
        };
      case "Batal":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "Disetujui":
        return {
          fontWeight: "bold",
          backgroundColor: "#E4F5C9",
          textColor: "#87CB16",
        };
      case "Ditolak":
        return {
          fontWeight: "bold",
          backgroundColor: "#FF0000",
          textColor: "#FFE6E6",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colKunjungan = [
    {
      name: "No",
      width: "100px",
      center: true,
      // selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
      selector: (row, index) => index + kunjungan.dataKunjungan.pagingCounter,
    },
    {
      name: "Nama Nasabah",
      minWidth: "250px",
      center: true,
      selector: (row) => row.calonNasabah.namaNasabah,
    },
    {
      name: "Tgl Kunjungan",
      minWidth: "150px",
      center: true,
      selector: (row) => moment(row.tglKunjungan).format("DD MMMM YYYY"),
    },
    {
      name: "Plafond",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.plafond);
        return formattedValue;
      },
    },
    {
      name: "Nett",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nett);
        return formattedValue;
      },
    },
    {
      name: "Keputusan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.keputusan,
      cell: (row) => {
        const statusStyle = getKeputusan(row.keputusan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.keputusan}
          </div>
        );
      },
    },
    {
      name: "Hasil",
      minWidth: "200px",
      center: true,
      selector: (row) => row.hasil,
      cell: (row) => {
        const hasil = row.hasil === "" ? "-" : row.hasil;
        const statusStyle = getHasil(hasil);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {hasil}
          </div>
        );
      },
    },
    {
      name: "Cabang",
      minWidth: "250px",
      center: true,
      selector: (row) => row.cabang.namaCabang,
    },
    {
      name: "Koordinator Cabang",
      minWidth: "250px",
      center: true,
      selector: (row) => row.korcab.nama,
    },
    {
      name: "Marketing",
      minWidth: "250px",
      center: true,
      selector: (row) => row.marketing.nama,
    },
    {
      name: "Supervisor",
      minWidth: "250px",
      center: true,
      selector: (row) => row.supervisor.nama,
    },
    {
      name: "Area Manager",
      minWidth: "250px",
      center: true,
      selector: (row) => row.areaManager.nama,
    },

    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setIsLoading(true);
    getAllKunjunganByLogin(dispatch, {
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        setIsLoading(false); // Setelah selesai memuat data
      })
      .catch((error) => {
        setIsLoading(false); // Jika terjadi error
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman]);
  return (
    <>
      <Modal
        size="lg"
        show={modalDetail}
        onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
            <Card className="stacked-form" style={{ border: 0 }}>
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Detail Kunjungan
                  <hr></hr>
                </Card.Title>
              </Card.Header>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {buktiKunjungan ? (
                  <img
                    src={buktiKunjungan}
                    alt="Bukti Kunjungan"
                    style={{ maxWidth: "60%", maxHeight: "50%" }}
                  />
                ) : (
                  <>
                    <div style={{ marginBottom: "1rem" }}>
                      <div
                        style={{
                          backgroundColor: "#E0D5FA",
                          color: "#703BE7",
                          borderRadius: "8px",
                          padding: "10px 15px",
                          boxShadow: "0 3px 8px rgba(0, 1, 0, 0.3)",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Maaf, masih belum ada bukti Kunjungan untuk saat ini..
                      </div>
                    </div>
                    <img
                      src={require("assets/img/sorry.jpg").default}
                      alt="Sorry"
                      style={{ maxWidth: "50%", borderRadius: "5px" }}
                    />
                  </>
                )}
              </Card.Body>
              <Card.Title
                as="h4"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Map
                <hr></hr>
              </Card.Title>
              <div style={{ height: "50vh", width: "58%", margin: "auto" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAXcaz5mPbGbZs4rrFVHLTANG0NLoBEp2I",
                  }}
                  center={{
                    lat: latitude || defaultProps.center.lat,
                    lng: longitude || defaultProps.center.lng,
                  }}
                  defaultZoom={defaultProps.zoom}
                >
                  {latitude !== null && longitude !== null && (
                    <AnyReactComponent
                      lat={latitude}
                      lng={longitude}
                      // text="My Marker"
                    />
                  )}
                </GoogleMapReact>
              </div>
            </Card>
          </div>
        </Col>
      </Modal>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  List Kunjungan
                  <hr></hr>
                </Card.Title>
                {/* <Tab.Content>
                  <Card style={{ border: 0, marginTop: 20 }}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <Col md={"3"}>
                        <div>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Status"
                            value={filterStatus}
                            onChange={(selectedOption) => {
                              setFilterStatus(selectedOption);
                              setPilihOpsi(selectedOption.value);
                            }}
                            onKeyDown={handleKeyDown}
                            options={[
                              { value: "", label: "All" },
                              ...listStatus,
                            ]}
                            placeholder="Pilih Status"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Tab.Content> */}
              </Card.Header>
              <Card.Body>
                {isLoading ? (
                  <div
                    className="loader-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "30vh",
                    }}
                  >
                    <Watch
                      visible={true}
                      height="80"
                      width="80"
                      radius="30"
                      color="#F75D59"
                      ariaLabel="watch-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <div>
                    <DataTable
                      columns={colKunjungan}
                      data={listDataKunjungan}
                      customStyles={customStyles}
                      paginationPerPage={totalPerHalaman}
                      // progressPending={isLoading}
                    />
                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label style={{ fontWeight: "bold" }}>
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChange}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={page}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-itemss-containers"
                            previousLinkClassName="pages-links-labelss"
                            nextClassName="pages-itemss-containers"
                            nextLinkClassName="pages-links-labelss"
                            breakClassName="pages-itemss-containers"
                            pageCount={kunjungan.dataKunjungan.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labelss"
                            pageClassName="pages-itemss-containers"
                            activeClassName="activees"
                            activeLinkClassName="aactivess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={page}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPage(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListKunjungan;
