import React from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Col,
  Modal,
  Tab,
  Row,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { tambahUser, getAllClient } from "stores";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser } from "stores";
import { editStatusUser } from "stores";
import { Dna } from "react-loader-spinner";
import { tambahKorcab } from "stores";
import { getAllKorcab } from "stores";
import { getAllCabang } from "stores";
import { penempatanKorcab } from "stores";
import { settingMenuKorcab } from "stores";

function ListBPD() {
  const dispatch = useDispatch();
  const storeClient = useSelector((state) => state.clientReducer);
  const korcab = useSelector((state) => state.korcabReducer);
  const storeCabang = useSelector((state) => state.cabangReducer);

  const auth = useSelector((state) => state.authReducer);
  // const role = auth.role;
  // console.log(role, "AUTH");

  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const [listKorcab, setListKorcab] = React.useState([]);
  const [pilihClient, setPilihClient] = React.useState(null);
  const [idClient, setIdClient] = React.useState("");
  const [modalKorcab, setModalKorcab] = React.useState(false);
  const [modalSettingMenu, setModalSettingMenu] = React.useState(false);
  const [modalPenempatan, setModalPenempatan] = React.useState(false);
  const [nama, setNama] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [listClient, setListClient] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [listCabang, setListCabang] = React.useState([]);
  const [idCabang, setIdCabang] = React.useState(null);
  const [pilihCabang, setPilihCabang] = React.useState(null);
  const [idKorcab, setIdKorcab] = React.useState("");
  const [listDetailCabang, setListDetailCabang] = React.useState([]);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [multipleSelect, setMultipleSelect] = React.useState([]);
  const [idWilayah, setIdWilayah] = React.useState({
    label: "",
    value: "",
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const submitSettingMenu = () => {
    const dataToSend = {
      idKorcab: idKorcab,
      menu: multipleSelect.map((option) => option.value),
    };
    settingMenuKorcab(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          getAllKorcab(dispatch);
          setModalSettingMenu(false);
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      });
  };

  const submitKorcab = () => {
    tambahKorcab({
      nama: nama,
      email: email,
      idClient: idClient,
    })
      .then((response) => {
        // console.log(response);
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            title: response.data.message,
            timer: 1000,
          });
          handleModalClose();
          getAllKorcab(dispatch);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("ERRORRRR", error);
      });
  };

  const submitPenempatan = () => {
    if (pilihCabang === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pemilihan Cabang tidak boleh kosong",
      });
    } else {
      penempatanKorcab({
        idKorcab: idKorcab,
        idCabang: idCabang,
      }).then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          handleModalClose();
          // setIdCabang("");
          // setPilihCabang("");
          getAllKorcab(dispatch);
        } else if (response.data.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      });
    }
  };

  const handleModalClose = () => {
    setModalKorcab(false);
    setPilihClient(null);
    setNama("");
    setEmail("");
    setIdClient("");
    setPilihCabang(null);
    setIdCabang(null);
    setModalPenempatan(false);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  React.useEffect(() => {
    let tmp =
      korcab.listKorcab &&
      korcab.listKorcab.map((val) => {
        return {
          ...val,
          action: (
            <>
              {auth.role === "super admin" || auth.role === "admin" ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Penempatan Korcab</Tooltip>}
                  >
                    <Button
                      disabled={val.status === "aktif" ? false : true}
                      className="mr-0"
                      variant="info"
                      style={{
                        marginTop: 5,
                        cursor:
                          val.status === "tidak aktif"
                            ? "not-allowed"
                            : "pointer",
                        backgroundColor:
                          val.status === "tidak aktif" ? "grey" : "#5F97E6",
                        border: 0,
                        padding: "7px 16px",
                        fontSize: "18px",
                      }}
                      onClick={() => {
                        setIdKorcab(val._id);
                        getAllCabang(dispatch, { idClient: val.idClient });
                        setModalPenempatan(!modalPenempatan);
                      }}
                    >
                      <i class="fas fa-map-marker-alt"></i>
                    </Button>
                  </OverlayTrigger>
                </>
              ) : (
                "-"
              )}
              {auth.role === "super admin" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Setting Menu</Tooltip>}
                >
                  <Button
                    className="ml-2"
                    style={{
                      marginTop: 5,
                      backgroundColor: "#496679",
                      border: 0,
                      padding: "7px 16px",
                      fontSize: "18px",
                    }}
                    onClick={() => {
                      setModalSettingMenu(!modalSettingMenu);
                      setIdKorcab(val._id);
                    }}
                  >
                    <i class="fas fa-user-cog"></i>
                  </Button>
                </OverlayTrigger>
              ) : null}
            </>
          ),
        };
      });
    setListKorcab(tmp);
  }, [korcab.listKorcab, auth]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeClient.listClient &&
      storeClient.listClient.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaClient,
        });
      });
    setListClient(tmp);
  }, [storeClient.listClient]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeCabang.listCabang &&
      storeCabang.listCabang.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaCabang,
        });
      });
    // console.log(tmp, "TMPPPPP");
    setListCabang(tmp);
  }, [storeCabang.listCabang]);

  const getStatusUser = (statusUser) => {
    switch (statusUser) {
      case "tidak aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3EEF9",
          textColor: "#1569C7",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 80, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colKorcab = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Kode User",
      minWidth: "200px",
      center: true,
      selector: (row) => row.kodeUser,
    },
    {
      name: "Nama",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Username",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Role",
      minWidth: "200px",
      center: true,
      selector: (row) => row.role,
    },
    {
      name: "Status",
      minWidth: "150px",
      center: true,
      // selector: (row) => (row.status === "aktif" ? "Aktif" : "Tidak Aktif"),
      selector: (row) => row.status,
      cell: (row) => {
        const statusStyle = getStatusUser(row.status);
        const tampilanLabel = row.status === "aktif" ? "Aktif" : "Tidak Aktif";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Cabang",
      minWidth: "250px",
      center: true,
      selector: (row) =>
        row.penempatan.length === 1 && !row.penempatan[0].namaCabang ? (
          <div style={{ fontWeight: "bold" }}>Belum Penempatan</div>
        ) : row.penempatan.length === 1 ? (
          row.penempatan[0].namaCabang
        ) : (
          <>
            <div>{row.penempatan[0].namaCabang},...</div>
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                setListDetailCabang(row);
                setModalDetail(true);
                // console.log(row);
              }}
            >
              Lihat Semua
            </div>
          </>
        ),
    },
    {
      name: "Aksi",
      minWidth: "150px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    getAllCabang(dispatch, { idWilayah: idWilayah.value })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    getAllClient(dispatch);
  }, [idWilayah]);

  React.useEffect(() => {
    getAllKorcab(dispatch, { idClient: "" })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    getAllClient(dispatch);
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={modalSettingMenu}
        onHide={() => setModalSettingMenu(!modalSettingMenu)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Setting Menu
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Col md="8">
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Pilih Menu"
                  name="multipleSelect"
                  closeMenuOnSelect={false}
                  isMulti
                  value={multipleSelect}
                  onChange={(value) => setMultipleSelect(value)}
                  options={[
                    {
                      value: "",
                      label: " Pilih Menu",
                      isDisabled: true,
                    },
                    { value: "Dashboard", label: "Dashboard" },
                    { value: "Client", label: "Client" },
                    { value: "User", label: "User" },
                    { value: "Cabang", label: "Cabang" },
                    { value: "Target", label: "Target" },
                    { value: "Pengajuan", label: "Pengajuan" },
                    { value: "Pencairan", label: "Pencairan" },
                    { value: "Kunjungan", label: "Kunjungan" },
                  ]}
                />
              </Col>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitSettingMenu}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalDetail}
        onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail Penempatan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Nama
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {listDetailCabang.nama}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Email
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {listDetailCabang.nopeg}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Penempatan
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <ul>
                      {listDetailCabang.penempatan &&
                        listDetailCabang.penempatan.map((val) => {
                          return (
                            <li>
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",
                                }}
                              >
                                {val.namaCabang}
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalPenempatan}
        onHide={() => {
          handleModalClose();
          setModalPenempatan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: 21,
                }}
              >
                Form Penempatan Koordinator Cabang
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black" }}>Pilih Cabang</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Cabang"
                    value={pilihCabang}
                    onChange={(value) => {
                      setPilihCabang(value);
                      setIdCabang(value.value);
                    }}
                    options={listCabang}
                    placeholder="Pilih Cabang"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitPenempatan}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalKorcab}
        onHide={() => {
          handleModalClose();
          setModalKorcab(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                // as="h4"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: 21,
                }}
              >
                Form Tambah Koordinator Cabang
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Koordinator Cabang
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNama(e.target.value);
                    }}
                    placeholder="Nama User"
                    type="text"
                  ></Form.Control>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Username
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Username"
                    type="email"
                  ></Form.Control>
                  {auth.role === "super admin" ? (
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Pilih Client
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Client"
                        value={pilihClient}
                        onChange={(value) => {
                          setPilihClient(value);
                          setIdClient(value.value);
                        }}
                        options={listClient}
                        placeholder="Pilih Client"
                      />
                    </Form.Group>
                  ) : null}
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={submitKorcab}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Tab.Content>
        <Card style={{ border: 0 }}>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col
              md={"4"}
              style={{
                marginTop: 20,
                marginRight: 25,
              }}
            >
              {auth.role === "super admin" || auth.role === "admin" ? (
                <Button
                  className="btn-wd mr-1"
                  // variant="primary"
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                    backgroundColor: "#DB5856",
                    fontWeight: "bold",
                    border: 0,
                  }}
                  onClick={() => setModalKorcab(!modalKorcab)}
                >
                  Tambah Koordinator Cabang
                </Button>
              ) : null}
            </Col>

            <Col md={"4"} style={{ marginTop: 25, marginRight: 25, order: 1 }}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="nc-icon nc-zoom-split icon-bold"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  style={{
                    paddingLeft: "10px",
                  }}
                  type="text"
                  value={searchText}
                  onChange={handleSearch}
                  placeholder="No Karyawan/Nama User"
                />
              </InputGroup>
            </Col>
          </Row>
        </Card>
      </Tab.Content>

      {isLoading ? (
        <div
          className="loader-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "30vh",
          }}
        >
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
          <p>Loading...</p>
        </div>
      ) : (
        <DataTable
          columns={colKorcab}
          data={listKorcab.filter(
            (row) =>
              row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
              row.nopeg.toLowerCase().includes(searchText.toLowerCase())
          )}
          // data={listUser}
          customStyles={customStyles}
          pagination
          paginationPerPage={itemsPerPage}
          onChangePage={handlePageChange}
        />
      )}
    </>
  );
}

export default ListBPD;
