import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { Dna, Watch } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getMarketing } from "stores";
import ListKunjunganByGetMarketing from "./ListKunjunganByGetMarketing";
import { exportPdfKunjungan } from "stores";

function ListMarketingByGetMarketing(data) {
  const dispatch = useDispatch();
  const kunjungan = useSelector((state) => state.kunjunganReducer);
  const auth = useSelector((state) => state.authReducer);
  const dataCabang = data.cabang;
  const ambilKunjungan = data.kunjungan;

  const [isLoading, setIsLoading] = React.useState(true);
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const [idCabang, setIdCabang] = React.useState("");
  const [idMarketing, setIdMarketing] = React.useState("");
  const [listDataMarketing, setListDataMarketing] = React.useState([]);
  const [sendKunjunganMarketing, setSendKunjunganMarketing] = React.useState(
    []
  );
  // const [sendDataCabang, setSendDataCabang] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleClick = () => {
    dispatch({ type: "SET_DETAIL_MARKETING", data: true });
  };

  const handleDetailsClick = (val) => {
    dispatch({ type: "SET_DETAIL_KUNJUNGAN_MARKETING", data: false });
    setSendKunjunganMarketing(val.kunjungan);
  };

  const bukaPDF = async (idMarketing) => {
    try {
      const data = {
        tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
        tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
        idMarketing: idMarketing,
      };
      const response = await exportPdfKunjungan(data);
      // console.log(response.base64, "AASASAAS");
      // const byteCharacters = atob(response.base64);
      // const byteNumbers = new Array(byteCharacters.length);
      // for (let i = 0; i < byteCharacters.length; i++) {
      //   byteNumbers[i] = byteCharacters.charCodeAt(i);
      // }
      // const byteArray = new Uint8Array(byteNumbers);
      // const blob = new Blob([byteArray], { type: "application/pdf" });
      // const blobUrl = URL.createObjectURL(blob);
      // window.open(blobUrl);
      const byteCharacters = atob(response.base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob from the byte array
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      const newTab = window.open(blobUrl, "_blank");
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = "Kunjungan Marketing.pdf";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Close the new tab if opened programmatically
      if (!newTab) {
        alert(
          "Pop-up blocker prevented opening the PDF in a new tab. Please allow pop-ups for this site."
        );
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  // React.useEffect(() => {
  //   let tmp = [];
  //   Object.entries(data).forEach(([key, value]) => {
  //     console.log(key, "VALUEE");
  //   });
  // });

  React.useEffect(() => {
    let tmp =
      data.data &&
      data.data.map((val) => {
        return {
          ...val,
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#F75D59",
                    border: 0,
                  }}
                  onClick={() => {
                    handleDetailsClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>{" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Download Pdf</Tooltip>}
              >
                <Button
                  className="ml-2"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                    backgroundColor: "#990012",
                    border: 0,
                    // cursor:
                    //   val.statusKoperasi === "active"
                    //     ? "pointer"
                    //     : "not-allowed",
                  }}
                  // disabled={val.statusKoperasi === "nonactive" ? true : false}
                  onClick={() => bukaPDF(val._id)}
                >
                  <i class="fas fa-file-download"></i>
                </Button>
              </OverlayTrigger>
            </>
          ),
        };
      });
    setListDataMarketing(tmp);
  }, [data.data]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const getStatusUser = (statusUser) => {
    switch (statusUser) {
      case "tidak aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3EEF9",
          textColor: "#1569C7",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colKunjungan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Nopeg",
      minWidth: "250px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Nama",
      minWidth: "250px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "NIK",
      minWidth: "250px",
      center: true,
      selector: (row) => row.nik,
    },
    {
      name: "Jabatan",
      minWidth: "200px",
      center: true,
      selector: (row) =>
        row.role === "marketing" ? "Sales Marketing" : "Direct Sales Marketing",
    },
    {
      name: "Total Kunjungan",
      minWidth: "250px",
      center: true,
      selector: (row) => `${row.kunjungan.length} Kunjungan`,
    },
    {
      name: "Status",
      minWidth: "150px",
      center: true,
      // selector: (row) => (row.status === "aktif" ? "Aktif" : "Tidak Aktif"),
      selector: (row) => row.status,
      cell: (row) => {
        const statusStyle = getStatusUser(row.status);
        const tampilanLabel = row.status === "aktif" ? "Aktif" : "Tidak Aktif";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setIsLoading(true);
    getMarketing(dispatch, {
      tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
      tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
      idCabang: idCabang,
      idMarketing: idMarketing,
    })
      .then((response) => {
        // setLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [tglAwal, tglAkhir]);

  return (
    <>
      {kunjungan.detailKunjunganMarketing ? (
        <Container fluid>
          <Button
            className="btn-transparent mr-2"
            type="button"
            variant="primary"
            size="md"
            style={{
              border: "none",
              backgroundColor: "#F75D59",
              marginBottom: 20,
              border: 0,
            }}
            onClick={handleClick}
          >
            <i className="fas fa-arrow-left"></i>
          </Button>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title
                    as="h3"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    List Marketing
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
                <Tab.Content>
                  <Card style={{ border: 0, marginTop: 20 }}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <Col md={2} style={{ marginTop: 20, marginLeft: 25 }}>
                        <h5
                          className="title mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Tanggal Awal
                        </h5>
                        <div>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0);
                              setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAwal}
                            initialValue={tglAwal}
                            timeFormat={false}
                          />
                        </div>
                      </Col>
                      <Col md={2} style={{ marginTop: 20 }}>
                        <h5
                          className="title mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Tanggal Akhir
                        </h5>
                        <div>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0);
                              setTglAkhir(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAkhir}
                            initialValue={tglAkhir}
                            timeFormat={false}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Tab.Content>
                <Card.Body>
                  {isLoading ? (
                    <div
                      className="loader-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "30vh",
                      }}
                    >
                      <Watch
                        visible={true}
                        height="80"
                        width="80"
                        radius="30"
                        color="#F75D59"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <div>
                      <DataTable
                        columns={colKunjungan}
                        data={listDataMarketing}
                        customStyles={customStyles}
                        pagination
                        paginationPerPage={itemsPerPage}
                        onChangePage={handlePageChange}
                        //   paginationPerPage={totalPerHalaman}
                        // progressPending={isLoading}
                      />
                      {/* <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label style={{ fontWeight: "bold" }}>
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChange}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={page}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-itemss-containers"
                            previousLinkClassName="pages-links-labelss"
                            nextClassName="pages-itemss-containers"
                            nextLinkClassName="pages-links-labelss"
                            breakClassName="pages-itemss-containers"
                            pageCount={kunjungan.dataKunjungan.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labelss"
                            pageClassName="pages-itemss-containers"
                            activeClassName="activees"
                            activeLinkClassName="aactivess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={page}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPage(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div> */}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <ListKunjunganByGetMarketing
            data={sendKunjunganMarketing}
            cabang={dataCabang}
          />
        </>
      )}
    </>
  );
}

export default ListMarketingByGetMarketing;
