import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { exportPdfKunjunganMobile } from "stores";
import moment from "moment";
import Swal from "sweetalert2";

const PdfKiki = () => {
  const useQuery = () => {
    return new URLSearchParams(window.location.search);
  };
  const query = useQuery();
  const tglAwal = query.get("tglAwal");
  const tglAkhir = query.get("tglAkhir");
  const idUser = query.get("idUser");

  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  let data = {
    tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
    tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
    idMarketing: idUser,
  };

  const bukaPDFMobile = async (data) => {
    setLoading(true);
    try {
      const response = await exportPdfKunjunganMobile(data);

      if (response && response.base64) {
        const byteCharacters = atob(response.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: "application/pdf" });

        // Create a link element, set its href to the Blob URL, and trigger download
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = "Kunjungan Marketing.pdf";
        downloadLink.click();
      } else {
        Swal.fire({
          icon: "error",
          title: "Export",
          text: response.data.message,
        });
      }

      setLoading(false);
    } catch (err) {
      console.error("Error:", err);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Export",
        text: "An error occurred while exporting PDF. Please try again later.",
      });
    }
  };

  useEffect(() => {
    bukaPDFMobile(data);
  }, []);

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <div className="loader"></div>
          <p>Loading...</p>
        </div>
      ) : (
        pdfUrl && <iframe src={pdfUrl} width="100%" height="1000px" />
      )}
      <style>
        {`
          .loader {
            border: 16px solid #f3f3f3;
            border-top: 16px solid #3498db;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            animation: spin 2s linear infinite;
            margin: 100px auto; /* Center the loader */
          }
          
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          .loading-container {
            text-align: center;
            margin: 100px auto;
          }

          .loading-container p {
            margin-top: 20px;
            font-size: 18px;
            color: #3498db;
          }
        `}
      </style>
    </div>
  );
};

export default PdfKiki;
