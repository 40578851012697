import React from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { Dna, Watch } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getMarketing } from "stores";
import { getKunjunganAllMarketing } from "stores";
import ReactPaginate from "react-paginate";
import ListKunjunganMarketing from "./ListKunjunganMarketing";

const KunjunganMarketing = () => {
  const dispatch = useDispatch();
  const kunjungan = useSelector((state) => state.kunjunganReducer);
  const auth = useSelector((state) => state.authReducer);
  const [isLoading, setIsLoading] = React.useState(true);
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const [idCabang, setIdCabang] = React.useState("");
  const [idMarketing, setIdMarketing] = React.useState("");
  const [listDataMarketing, setListDataMarketing] = React.useState([]);
  const [sendDataKunjungan, setSendDataKunjungan] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handleFilterChange = () => {
    setIsLoading(true);
    setPage(0);
    getKunjunganAllMarketing(dispatch, {
      tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
      tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        // setLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleDetailsClick = (val) => {
    dispatch({ type: "SET_DETAIL_KUNJUNGAN", data: false });
    setSendDataKunjungan(val.kunjungan);
  };

  React.useEffect(() => {
    let tmp =
      kunjungan.getKunjunganAllMarketing.docs &&
      kunjungan.getKunjunganAllMarketing.docs.map((val) => {
        return {
          ...val,
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#F75D59",
                    border: 0,
                  }}
                  onClick={() => {
                    handleDetailsClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>{" "}
            </>
          ),
        };
      });
    setListDataMarketing(tmp);
  }, [kunjungan.getKunjunganAllMarketing]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  const getStatusUser = (statusUser) => {
    switch (statusUser) {
      case "tidak aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3EEF9",
          textColor: "#1569C7",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colKunjungan = [
    {
      name: "No",
      width: "100px",
      center: true,
      //   selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
      selector: (row, index) =>
        index + kunjungan.getKunjunganAllMarketing.pagingCounter,
    },
    {
      name: "Nama",
      minWidth: "250px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Nopeg",
      minWidth: "250px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Nama Cabang",
      minWidth: "250px",
      center: true,
      selector: (row) => (!row.cabang ? "-" : row.cabang.namaCabang),
    },
    {
      name: "NIK",
      minWidth: "250px",
      center: true,
      selector: (row) => row.nik,
    },
    {
      name: "Jabatan",
      minWidth: "250px",
      center: true,
      selector: (row) =>
        row.role === "marketing" ? "Sales Marketing" : "Direct Marketing",
    },
    {
      name: "Total Kunjungan",
      minWidth: "250px",
      center: true,
      selector: (row) => `${row.totalKunjungan} Kunjungan`,
    },
    {
      name: "Status",
      minWidth: "150px",
      center: true,
      // selector: (row) => (row.status === "aktif" ? "Aktif" : "Tidak Aktif"),
      selector: (row) => row.status,
      cell: (row) => {
        const statusStyle = getStatusUser(row.status);
        const tampilanLabel = row.status === "aktif" ? "Aktif" : "Tidak Aktif";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setIsLoading(true);
    getKunjunganAllMarketing(dispatch, {
      tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
      tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
      cari: "",
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        // setLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [tglAwal, tglAkhir, page, totalPerHalaman]);
  return (
    <>
      {kunjungan.detailGetKunjunganAllMarketing ? (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title
                    // as="h3"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      fontSize: 25,
                    }}
                  >
                    list kunjungan marketing
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
                <Tab.Content>
                  <Card style={{ border: 0, marginTop: 20 }}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <Col md={2} style={{ marginLeft: 25 }}>
                        <h5
                          className="title mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Tanggal Awal
                        </h5>
                        <div>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0);
                              setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAwal}
                            initialValue={tglAwal}
                            timeFormat={false}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <h5
                          className="title mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Tanggal Akhir
                        </h5>
                        <div>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0);
                              setTglAkhir(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAkhir}
                            initialValue={tglAkhir}
                            timeFormat={false}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  <Card style={{ border: 0 }}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: 20,
                      }}
                    >
                      <Col md={"4"}>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                            }}
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Nama Marketing"
                            onKeyDown={handleKeyDown}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearch}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Card>
                </Tab.Content>
                <Card.Body>
                  {isLoading ? (
                    <div
                      className="loader-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "30vh",
                      }}
                    >
                      <Watch
                        visible={true}
                        height="80"
                        width="80"
                        radius="30"
                        color="#F75D59"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <div>
                      <DataTable
                        columns={colKunjungan}
                        data={listDataMarketing}
                        customStyles={customStyles}
                        paginationPerPage={totalPerHalaman}
                        progressPending={isLoading}
                      />
                      <div style={{ marginTop: 35 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col>
                            <Row style={{ alignItems: "center" }}>
                              <div
                                style={{
                                  paddingRight: 0,
                                  marginLeft: 20,
                                  marginRight: 10,
                                }}
                              >
                                <label style={{ fontWeight: "bold" }}>
                                  Rows per page
                                </label>
                              </div>
                              <Col sm="3" style={{ paddingLeft: 0 }}>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="Pilih Client"
                                  value={options.find(
                                    (option) => option.value === totalPerHalaman
                                  )}
                                  onChange={handlePageSizeChange}
                                  options={options}
                                  placeholder="Rows per page"
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <ReactPaginate
                              forcePage={page}
                              containerClassName="paginations justify-content-end"
                              previousClassName="pages-itemss-containers"
                              previousLinkClassName="pages-links-labelss"
                              nextClassName="pages-itemss-containers"
                              nextLinkClassName="pages-links-labelss"
                              breakClassName="pages-itemss-containers"
                              pageCount={
                                kunjungan.getKunjunganAllMarketing.totalPages
                              }
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={1}
                              pageLinkClassName="pages-links-labelss"
                              pageClassName="pages-itemss-containers"
                              activeClassName="activees"
                              activeLinkClassName="aactivess"
                              nextLabel=" > "
                              previousLabel=" < "
                              initialPage={page}
                              disableInitialCallback={true}
                              onPageChange={(val) => {
                                setPage(val.selected);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <ListKunjunganMarketing data={sendDataKunjungan} />
        </>
      )}
    </>
  );
};

export default KunjunganMarketing;
