import React from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { tambahTargetKorwil } from "stores";
import { getAllKorwil } from "stores";
import { getAllClient } from "stores";
import CurrencyInput from "react-currency-input-field";
import { Dna } from "react-loader-spinner";

function InputTargetKorwil() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const korwil = useSelector((state) => state.korwilReducer);
  const storeClient = useSelector((state) => state.clientReducer);
  const [listClient, setListClient] = React.useState([]);
  const [idClient, setIdClient] = React.useState("");
  const [tanggal, setTanggal] = React.useState("");
  const [listKorwil, setListKorwil] = React.useState([]);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [filterClient, setFilterClient] = React.useState(null);
  const [statusOmit, setStatusOmit] = React.useState(true);
  const [inputDisable, setInputDisable] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  // const [currentPage, setCurrentPage] = React.useState(1);
  // const itemsPerPage = 10;

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  const options =
    auth.role === "super admin"
      ? [{ value: "", label: "All" }, ...listClient]
      : listClient;

  const handleFilterChange = (selectedOption) => {
    setIdClient(selectedOption.value);
    setFilterClient(selectedOption);
    setInputDisable(false);
    getAllKorwil(dispatch, { idClient: selectedOption.value });
  };

  const submitTargetKorwil = () => {
    setStatusOmit(false);
    if (tanggal === "" || idClient === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Harap lengkapi tanggal dan Pilih BPD",
      });
    } else {
      let successCount = 0;
      let errorCount = 0;
      const totalTargets = listKorwil.length;
      listKorwil.forEach((val, index) => {
        setTimeout(function () {
          tambahTargetKorwil({
            tanggal: tanggal,
            nominal: val.nominal,
            idKoordinatorWilayah: val._id,
            idClient: idClient,
            idWilayah: "",
          }).then((response) => {
            let array = [...listKorwil];
            if (response.data.status === 200) {
              array[index].status = "berhasil";
              successCount++;
            } else {
              array[index].status = "gagal";
              errorCount++;
            }
            setListKorwil(array);
            if (index === totalTargets - 1) {
              if (successCount === totalTargets) {
                Swal.fire({
                  icon: "success",
                  title: "Sukses",
                  text: "Semua target berhasil di set.",
                }).then(() => {
                  dispatch({
                    type: "SET_STATUS_INPUT_TARGET_KORWIL",
                    data: true,
                  });
                });
              } else if (errorCount === totalTargets) {
                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  text: "Semua target gagal di set.",
                }).then(() => {
                  dispatch({
                    type: "SET_STATUS_INPUT_TARGET_KORWIL",
                    data: true,
                  });
                });
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "Peringatan",
                  text: "Beberapa target berhasil di set, namun ada yang gagal.",
                }).then(() => {
                  dispatch({
                    type: "SET_STATUS_INPUT_TARGET_KORWIL",
                    data: true,
                  });
                });
              }
            }
          });
        }, 1000 * index);
      });
    }
  };

  React.useEffect(() => {
    let tmp =
      korwil.listKorwil &&
      korwil.listKorwil.map((val) => {
        return {
          ...val,
          nominal: 0,
          status: null,
          action: (
            <>
              {" "}
              <Button
                className="mr-0"
                variant="info"
                style={{ marginTop: 5 }}
                onClick={() => setModalEdit(!modalEdit)}
              >
                Edit
              </Button>
            </>
          ),
        };
      });
    setListKorwil(tmp);
  }, [korwil.listKorwil]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeClient.listClient &&
      storeClient.listClient.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaClient,
        });
      });

    setListClient(tmp);
  }, [storeClient.listClient]);

  const handleClick = () => {
    dispatch({ type: "SET_STATUS_INPUT_TARGET_KORWIL", data: true });
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colTargetKorwil = [
    {
      name: "No",
      width: "70px",
      center: true,
      selector: (row, index) => index + 1,
    },
    {
      name: "No Karyawan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Nama Koordinator Wilayah",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Nominal",
      minWidth: "200px",
      center: true,
      cell: (row, index) => (
        <CurrencyInput
          style={{ padding: 5, borderRadius: 5 }}
          name="nominal"
          placeholder="Masukkan nominal"
          disabled={inputDisable}
          prefix="Rp "
          onValueChange={(value) => {
            setListKorwil([
              ...listKorwil.slice(0, index),
              { ...row, nominal: value },
              ...listKorwil.slice(index + 1),
            ]);
          }}
        />
      ),
    },
    {
      name: "Status",
      minWidth: "200px",
      center: true,
      omit: statusOmit,
      selector: (row) =>
        row.status === null ? (
          "Loading......."
        ) : row.status === "berhasil" ? (
          <i className="fas fa-check" style={{ color: "green" }}></i>
        ) : row.status === "loading" ? (
          "Loading..."
        ) : (
          <i className="fas fa-times" style={{ color: "red" }}></i>
        ),
    },
    // {
    //   name: "Aksi",
    //   minWidth: "200px",
    //   center: true,
    //   selector: (row) => row.action,
    // },
  ];

  React.useEffect(() => {
    getAllKorwil(dispatch, { idClient: "" })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    getAllClient(dispatch);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: "inherit",
                  }}
                >
                  Set Target Koordinator Wilayah
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Card style={{ border: 0 }}>
                  <Row>
                    {/* <Form.Group> */}
                    <Col md={"3"}>
                      <label style={{ marginLeft: 15, color: "black" }}>
                        Tanggal
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setTanggal(e.target.value + "-01");
                        }}
                        placeholder="Input Tanggal"
                        type="month"
                      ></Form.Control>
                    </Col>
                    <Col md={"3"}>
                      <label style={{ color: "black" }}>Pilih Client</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Pilih Client"
                        value={filterClient}
                        onChange={(selectedOption) =>
                          handleFilterChange(selectedOption)
                        }
                        options={options}
                        placeholder="Pilih Client"
                      />
                    </Col>
                    <Col style={{ marginTop: 25, marginLeft: 20 }}>
                      <div>
                        <Button
                          className=" mr-1"
                          variant="danger"
                          style={{}}
                          onClick={handleClick}
                        >
                          Cancel
                        </Button>
                        <Button
                          className=" mr-1"
                          variant="success"
                          style={{
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                          }}
                          onClick={submitTargetKorwil}
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
                {isLoading ? (
                  <div
                    className="loader-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "30vh",
                    }}
                  >
                    <Dna
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <DataTable
                    columns={colTargetKorwil}
                    data={listKorwil}
                    customStyles={customStyles}
                    // pagination
                    // paginationPerPage={itemsPerPage}
                    // onChangePage={handlePageChange}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default InputTargetKorwil;
